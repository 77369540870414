import ContentDE from '../assets/content_de.json';
import ContentEN from '../assets/content_en.json';

const FilterContent = [];

let RAWContent = ContentDE;
let RAWContent_EN = ContentEN;

const palaeolithic = [
  'a-second-stellmoor',
  'camp-sites-with-a-lake-view',
  'the-ahrensburg-tunnel-valley',
  'campsites-in-the-seine-valley',
  'the-palaeolithic-in-schleswig-holstein'
];

const mesolithic = [
  'archaeology-in-the-hanseatic-city-of-luebeck',
  'a-treasure-chamber-of-schleswig-holstein',
  'north-frisian-wadden-sea',
  'the-seafloor-as-an-archive',
  '8-000-years-of-settlement-by-the-river',
  'late-mesolithic-hunters-and-fishers-in-the-satrupholmer-moor',
  'a-tough-nut-to-crack',
  'das-aelteste-graeberfeld-deutschlands-bei-gross-fredenwalde',
  'the-mesolithic-in-schleswig-holstein'
];

const neolithic = [
  'between-identity-building-and-destruction',
  'landscape-archaeology-in-eastern-holstein',
  'the-ox-road',
  'incipient-globalisation-in-southeastern-europe',
  'beethoven-and-archaeology',
  'the-grave-field-of-flintbek',
  'monumental-landscapes',
  'monumentality-and-ancestor-worship',
  'new-forms-of-coexistence',
  'albersdorf-dieksknoell-bredenhoop-and-brutkamp',
  'wolkenwehe',
  'stolniceni-republik-moldau',
  'the-rise-and-fall-of-giant-settlements-in-eastern-europe',
  'complex-settlement-layout-of-a-democratically-organised-community',
  'megalithlandschaften-der-altmark-gemeinsam-erforscht',
  'buedelsdorf-borgstedt',
  'a-story-of-three-neighbourhoods',
  'rinnukalns',
  'okoliste',
  'prehistory-at-the-bottom-of-the-baltic-sea',
  'the-neolithic'
];

const bronzeAge = [
  'schuby-through-the-ages',
  'four-at-a-stroke',
  'bruszczewo-in-greater-poland',
  'a-demonstration-of-power-along-the-ox-road',
  'smoszew',
  'environment-and-economy-of-the-hittites',
  'mang-de-bargen-bornhoeved',
  'traces-of-settlements-under-an-early-bronze-age-burial-mound',
  'kakucs-turjan-moegoett',
  'le-ki-male',
  'schleswig-holstein-in-the-bronze-age'
];

const preRomanIron = [
  'schuby-through-the-ages',
  'the-early-roman-archaeological-site-of-hoby',
  'the-thorsberger-moor',
  'graves-and-settlements-in-todesfelde-district-of-segeberg',
  'a-settlement-site-at-burg-auf-fehmarn-district-of-ostholstein',
  'the-pre-roman-iron-age-in-schleswig-holstein'
];

const romanPeriod = [
  'what-to-feed-on-in-times-of-crisis',
  'the-wooden-chamber-grave-of-poprad-matejovce',
  'the-only-gate-in-the-danevirke',
  'massive-and-mighty-the-danevirke',
  'bog-bodies-from-schleswig-holstein',
  'frankish-glass-in-the-dunes-of-mrum',
  'wittenborn-eine-voelkerwanderungs-zeitliche-siedlung',
  'nettelsee',
  'a-roman-period-settlement-in-heiligenhafen',
  'the-megalithic-landscape-of-central-sulawesi',
  'a-settlement-in-schashagen',
  'schuby-through-the-ages',
  'the-roman-imperial-period-and-the-migration-period-in-schleswig-holstein'
];

const earlyMiddleAge = [
  'starigard-oldenburg',
  'between-identity-building-and-destruction',
  'between-vikings-and-the-hanseatic-league',
  'the-castrum-plune',
  'from-a-single-object-hoard-to-a-treasure-of-silver',
  'haithabu-excavation-2017',
  'early-north-frisian-trade-activities',
  'wiskiauten-mochovoe',
  'early-medieval-castles-in-nordalbingia',
  'the-double-ditched-enclosures-of-heiligenstedten-and-aukrug-buenzen',
  'elites-in-the-hinterland',
  'birka',
  'hedeby',
  'saxons-slavs-frisians-and-danes'
];

const middleAge = [
  'in-the-shadow-of-the-castle',
  'st-catharina',
  'eight-centuries-on-the-shovel',
  'rungholt',
  'luebeck',
  'on-the-trail-of-immigrants',
  'high-medieval-wrecks-from-the-schlei',
  'archaeology-in-the-hanseatic-city-of-luebeck',
  'boomtown-schleswig',
  'premodern-middle-ages-and-early-modern-period-and-modern-period'
];

const mordernTime = [
  'a-person-behind-each-stone',
  'the-second-house-for-the-dead',
  'sunken-sought-found',
  'nomads-of-the-taiga',
  'wrecks-in-the-intertidal-zone-of-the-north-sea',
  'abandoned-cities-of-the-steppe',
  'the-lindormen-and-the-fehmarn-belt-fixed-link',
  'premodern-middle-ages-and-early-modern-period-and-modern-period'
];

const now = [
  'archaeological-monument-preservation-and-archaeology-of-modernity-as-contributions-to-memorial-work',
  'balticrim',
  'the-johanna-mestorf-academy',
  'the-new-management-plan',
  'project-group-for-maritime-and-limnic-archaeology-amla',
  'volunteering-in-museums',
  'a-brief-history-of-archaeology-in-schleswig-holstein',
  'closing-a-gap',
  'graduate-school-human-development-in-landscapes',
  'collaboration-starts-with-trust',
  'collaborative-projects-in-the-field-of-archaeology-of-modernity',
  'monumentality-and-social-differentiation-in-the-neolithic',
  'public-relations-in-archaeological-heritage-management',
  'regiobranding',
  'roots',
  'scales-of-transformation',
  'persons-of-trust',
  'rediscover-science-communication'
];

const system = ['datenschutz', 'impressum', 'info'];

function addContent(sortedContent, rawContent, array, prefix, kapitelName) {
  for (const id of array) {
    const dummy = Object.assign(
      {},
      rawContent.find((element) => element.id === id)
    );
    dummy.id = prefix + dummy.id;
    dummy.kapitel = [kapitelName];
    sortedContent.push(dummy);
  }
}

function buildArray(sortArray, contentArray) {
  addContent(sortArray, contentArray, palaeolithic, 'pa_', 'kap09');
  addContent(sortArray, contentArray, mesolithic, 'me_', 'kap08');
  addContent(sortArray, contentArray, neolithic, 'ne_', 'kap07');
  addContent(sortArray, contentArray, bronzeAge, 'br_', 'kap06');
  addContent(sortArray, contentArray, preRomanIron, 'pr_', 'kap05');
  addContent(sortArray, contentArray, romanPeriod, 'ro_', 'kap04');
  addContent(sortArray, contentArray, earlyMiddleAge, 'ea_', 'kap03');
  addContent(sortArray, contentArray, middleAge, 'mi_', 'kap02');
  addContent(sortArray, contentArray, mordernTime, 'mo_', 'kap01');
  addContent(sortArray, contentArray, now, 'no_', 'kap00');
  addContent(sortArray, contentArray, system, '', 'system');
}

let sortedContent = [];
let sortedContent_en = [];

buildArray(sortedContent, RAWContent);
buildArray(sortedContent_en, RAWContent_EN);

for (const [index, element] of sortedContent.entries()) {
  // console.log(element.id);
  let tempArray = {
    id: element.id,
    type: element.type,
    isActive: false,
    isInFilter: false,
    map: element.map,
    title_de: element.text.headline,
    title_en: sortedContent_en[index].text.headline,
    tag: []
  };

  let tempTag = [];
  // Add filterable Tags
  if (element.meta?.kategorie !== undefined) {
    tempTag = tempTag.concat(element.meta.kategorie);
  }

  if (element.meta?.funde !== undefined) {
    tempTag = tempTag.concat(element.meta.funde);
  }

  if (element.meta?.autoren !== undefined) {
    tempTag = tempTag.concat(element.meta.autoren);
  }

  if (tempTag === []) {
    tempTag = tempTag = ['unset'];
  }

  tempArray.tag = tempTag;
  for (const [index, tempArrayKey] of element.kapitel.entries()) {
    if (index > 0) {
      tempArray.kapitel = tempArrayKey;
      tempArray.id = element.id + '_i' + index;
    } else {
      tempArray.kapitel = tempArrayKey;
      tempArray.id = element.id;
    }
    FilterContent.push({ ...tempArray });
  }
}

const FullContent = sortedContent;
const FullContentEN = sortedContent_en;

export { FullContent, FullContentEN, FilterContent };

<template>
  <div class="Meta" v-bind:style="{ color: keyColor }">
    <Autoren />
    <Map />
    <ZeitlicheEinordnung />
    <Grabungszeit />
    <Standort />
    <Merkmale />
    <KoopZeitraum />
    <Website />
    <MiniMap></MiniMap>
    <Logos></Logos>
    <Beteiligte></Beteiligte>
    <div class="tagbox">
      <Funde />
      <Kategorie />
    </div>
  </div>
</template>
<script>
import Autoren from '../Elements/Meta/Autoren';
import Map from '../Elements/Meta/Map';
import ZeitlicheEinordnung from '../Elements/Meta/ZeitlicheEinordnung';
import Grabungszeit from '../Elements/Meta/Grabungszeit';
import Standort from '../Elements/Meta/Standort';
import Merkmale from '../Elements/Meta/Merkmale';
import KoopZeitraum from '../Elements/Meta/KoopZeitraum';
import Website from '../Elements/Meta/Website';
import Funde from '../Elements/Meta/Funde';
import Kategorie from '../Elements/Meta/Kategorie';
import MiniMap from '../Elements/Meta/MiniMap';
import Logos from '../Elements/Meta/Logos';
import Beteiligte from '../Elements/Meta/Beteiligte';
import Globals from '../../services/globals';

export default {
  name: 'MetaBar',
  components: {
    Beteiligte,
    Kategorie,
    Funde,
    Website,
    KoopZeitraum,
    Merkmale,
    Standort,
    Grabungszeit,
    ZeitlicheEinordnung,
    Autoren,
    Map,
    MiniMap,
    Logos
  },
  computed: {
    keyColor() {
      return Globals.COLORS[this.$store.state.activeContentBlock.kapitel];
    },
    getkey() {
      console.log(this.$store.state.activeContentBlock.id);
      return this.$store.state.activeContentBlock.id + 'autoren';
    }
  }
};
</script>
<style scoped>
.Meta {
  grid-area: Meta;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
  background: white;
}

.tagbox {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
@media only screen and (max-width: 425px) {
  .Meta {
    padding: 1em;
  }
}
</style>

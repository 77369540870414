<template>
  <div class="Content" :key="textKey">
    <GalleryImage
      @openLightBox="onOpenLightbox"
      :activeImage="activeImage"
    ></GalleryImage>
    <Thumbnails
      @openLightBox="onOpenLightbox"
      @setActiveImage="setActiveImage"
    ></Thumbnails>
    <transition name="fade">
      <LightBox
        @close="closeLightbox"
        v-if="lightboxActive"
        :activeImage="activeImage"
      ></LightBox>
    </transition>
    <HeaderImage></HeaderImage>
    <TextBlock />
  </div>
</template>
<script>
import TextBlock from '../Elements/TextBlock.vue';
import HeaderImage from '../Elements/HeaderImage';
import Thumbnails from '../Elements/Thumbnails';
import GalleryImage from '../Elements/GalleryImage';
import LightBox from '../Elements/LightBox';

export default {
  name: 'ArtikelView',
  components: { LightBox, GalleryImage, Thumbnails, TextBlock, HeaderImage },
  data: function () {
    return {
      activeImage: 0,
      lightboxActive: false,
      bufferID: 'firstID'
    };
  },
  computed: {
    textKey() {
      return this.$store.state.activeContentBlock.id + '_textblock';
    }
  },
  methods: {
    onOpenLightbox() {
      this.lightboxActive = true;
    },
    setActiveImage(value) {
      this.activeImage = value;
    },
    closeLightbox() {
      this.lightboxActive = false;
      this.activeImage = 0;
    }
  },
  beforeUpdate() {
    if (this.bufferID !== this.$store.state.activeContentBlock) {
      this.activeImage = 0;
      this.bufferID = this.$store.state.activeContentBlock;
    }
  }
};
</script>
<style scoped>
.Content {
  justify-self: center;
  max-width: 720px;
  grid-area: Content;
  display: grid;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  padding: 0 2em;
}
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: rgb(238, 238, 238);
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(206, 206, 206);
}

@media only screen and (max-width: 480px) {
  .Content {
    background-color: white;
    max-width: 90%;
    height: unset;
    padding: 10px;
    padding-top: 2em;
  }
}
</style>

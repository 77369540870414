<template>
  <div class="infoBox">
    <h4 :style="{ color: colorHigh }" class="label">{{ label }}</h4>
    <p
      :style="{ color: colorLow }"
      class="textstyle_metaBold"
      v-html="content"
    ></p>
  </div>
</template>

<script>
export default {
  name: 'InfoBox',
  props: {
    label: String,
    content: String,
    colorScheme: String
  },
  computed: {
    colorHigh() {
      return this.$store.state.colorHigh;
    },
    colorLow() {
      return this.$store.state.colorLow;
    }
  }
};
</script>

<style scoped>
.infoBox {
  margin-left: 1em;
  width: 90%;
}
p {
  margin-top: 2px;
  line-height: 1.3;
}

.label {
  margin-bottom: 0;
  margin-top: 0.5em;
}
</style>

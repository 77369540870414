<template>
  <div
    class="MobileSidebar"
    @click="toggleSidebar"
    v-bind:style="{ background: keyColor }"
  >
    <img
      v-bind:style="{ background: keyColor }"
      :class="{ rotate: sidebarEnabled }"
      class="ToggleSidebar"
      src="../../assets/icons/chevron-back-outline.svg"
      alt="back"
    />
  </div>
  <div class="Headlines" :class="{ hideSidebar: !sidebarEnabled }">
    <MobileKapitel :title="lng.kap00" filter="kap00" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap01" filter="kap01" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap02" filter="kap02" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap03" filter="kap03" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap04" filter="kap04" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap05" filter="kap05" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap06" filter="kap06" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap07" filter="kap07" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap08" filter="kap08" @close="closeSideBar" />
    <MobileKapitel :title="lng.kap09" filter="kap09" @close="closeSideBar" />
  </div>
</template>
<script>
import Globals from '../../services/globals';
import MobileKapitel from '../Elements/MobileKapitel';
import { sidebar } from '../../services/translation';

export default {
  name: 'MobileNavBar',
  components: { MobileKapitel },
  data: function () {
    return {
      sidebarEnabled: false
    };
  },
  computed: {
    keyColor() {
      return Globals.COLORS[this.$store.state.activeContentBlock.kapitel];
    },
    lng() {
      if (this.$store.state.activeLng === 'de') {
        return sidebar.de;
      }
      return sidebar.en;
    }
  },
  methods: {
    toggleSidebar() {
      this.sidebarEnabled = !this.sidebarEnabled;
      document.getElementsByClassName('Headlines')[0].scrollTop = 0;
    },
    closeSideBar() {
      this.sidebarEnabled = false;
      document.getElementsByClassName('Headlines')[0].scrollTop = 0;
    }
  }
};
</script>
<style scoped>
@media only screen and (max-width: 1024px) {
  .MobileSidebar {
    display: block;
    width: 2em;
    padding: 0.5em 0;
    position: fixed;
    z-index: 3;
    top: 4em;
    right: 0;
    background: #386eb9;
    border-radius: 5px 0 0 5px;
  }
  .Headlines {
    position: fixed;
    background: #ffffff;
    z-index: 2;
    height: 100vh;
    overflow: auto;
    padding: 0 2em;
    padding-bottom: 4em;
    right: 0;
    max-width: 425px;
    top: 0;
    transition: right 500ms;
    box-sizing: border-box;
  }
}

.hideSidebar {
  right: -500px;
  transition: right 500ms;
}

.rotate {
  transform: scale(-1);
  transition: all 500ms;
}
.ToggleSidebar {
  transition: all 500ms;
}

@media only screen and (max-width: 425px) {
  .Headlines {
    max-width: calc(100vw - 4em);
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';

let startpoint = 'en';

if (navigator.language === 'de') {
  startpoint = 'de';
}

const routes = [
  {
    path: '/',
    redirect: '/' + startpoint + '/info',
    name: 'Home',
    component: Home
  },
  {
    path: '/de/:id',
    name: 'de',
    props: true,
    component: Home
  },
  {
    path: '/en/:id',
    name: 'en',
    props: true,
    component: Home
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

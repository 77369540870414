<template>
  <div class="autoren" v-if="isValid" :key="autoren[0]">
    <h4 class="label">{{ label }}</h4>
    <ul>
      <li
        class="textstyle_metaBold"
        v-for="autor in autoren"
        :key="autor"
        @click="click(autor)"
        v-bind:class="{ searchItem: isFilter(autor) }"
      >
        {{ autor }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Autoren',
  computed: {
    isValid() {
      return this.$store.state.activeContentBlock?.meta?.autoren !== undefined;
    },
    autoren() {
      return this.$store.state.activeContentBlock.meta.autoren;
    },
    label() {
      if (this.$store.state.activeLng === 'de') {
        if (this.autoren.length > 1) {
          return 'Autoren';
        }
        if (this.$store.state.activeContentBlock.meta.female === 'false') {
          return 'Autor';
        }
        return 'Autorin';
      }
      if (this.autoren.length > 1) {
        return 'Authors';
      }
      return 'Author';
    }
  },
  methods: {
    click(filter) {
      if (filter === this.$store.state.filter) {
        filter = '';
      }
      this.$store.dispatch('setFilter', filter);
    },
    isFilter(id) {
      return id === this.$store.state.filter;
    }
  }
};
</script>

<style scoped>
.label {
  margin-bottom: 0;
  margin-top: 0;
}

.searchItem {
  /*text-decoration: underline;*/
}
.searchItem:before {
  content: '\e800  ';
  font-family: 'fontello';
  font-size: 1em;
  line-height: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 0.1em;
}

li {
  margin-right: 1em;
  float: left;
  cursor: pointer;
}

.autoren {
  margin-bottom: 1em;
  margin-left: 1em;
  min-height: 4.5em;
}
</style>

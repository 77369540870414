<template>
  <div class="infoBox" v-if="showPanel">
    <h4 :style="{ color: colorHigh }" class="label">Website</h4>
    <a
      :style="{ color: colorLow }"
      class="textstyle_metaBold"
      v-html="content"
    ></a>
  </div>
</template>

<script>
export default {
  name: 'Website',
  computed: {
    colorHigh() {
      return this.$store.state.colorHigh;
    },
    colorLow() {
      return this.$store.state.colorLow;
    },
    content() {
      return this.$store.state.activeContentBlock.meta?.website;
    },
    showPanel() {
      return this.content !== undefined;
    }
  }
};
</script>

<style scoped>
.infoBox {
  margin-left: 1em;
}
a {
  margin-top: 2px;
  line-height: 1.3;
}

.label {
  margin-bottom: 0;
}
</style>

const header = {
  de: {
    titel: 'Archäologische Nachrichten 2020 digital',
    info: 'Start',
    datenschutz: 'Datenschutz',
    impressum: 'Impressum'
  },
  en: {
    titel: 'Archaeological News 2020 digital',
    info: 'Start',
    datenschutz: 'Privacy',
    impressum: 'imprint'
  }
};

module.exports.header = header;

const sidebar = {
  de: {
    kap00: 'Allgemein',
    kap01: '1500–heute \n Neuzeit',
    kap02: '1100–1500 n. Chr. \n Mittelalter',
    kap03: '700–1100 n. Chr. \n Frühmittelalter',
    kap04: '0–700 n. Chr. \n Römische Kaiserzeit \n Völkerwanderungszeit',
    kap05: '600 v. Chr.–0 n. Chr. \n Vorrömische Eisenzeit',
    kap06: '1700–600 v. Chr. \n Bronzezeit',
    kap07: '4100–1700 v. Chr. \n Neolithikum',
    kap08: '9600–4100 v. Chr. \n Mesolithikum',
    kap09: '105.000–9600 v. Chr. \n Paläolithikum'
  },
  en: {
    kap00: 'General',
    kap01: '1500–today \n Modern times',
    kap02: '1100–1500 n. Chr. \n Middle Ages',
    kap03: '700–1100 n. Chr. \n Early middle ages',
    kap04: '0–700 n. Chr. \n Roman Imperial Era \n Migration period ',
    kap05: '600 v. Chr.–0 n. Chr.\n Pre-Roman Iron Age',
    kap06: '1700–600 v. Chr. \n Bronze age',
    kap07: '4100–1700 v. Chr. \n Neolithic',
    kap08: '9600–4100 v. Chr. \n Mesolithic',
    kap09: '105,000–9600 v. Chr. \n Paleolithic'
  }
};
module.exports.sidebar = sidebar;

const meta = {
  de: {
    autor: 'Autor',
    autoren: 'Autoren',
    zeitlicheEinordung: 'Zeitliche Einordnung',
    grabungsZeit: 'Grabungszeit',
    standort: 'Standort',
    merkmale: 'merkmale',
    funde: 'Funde',
    kategorie: 'Kategorie',
    koopzeitraum: 'KOOPZEITRAUM',
    beteiligte: 'Beteiligte'
  },
  en: {
    autor: 'Author',
    autoren: 'Authors',
    zeitlicheEinordung: 'Temporal classification',
    grabungsZeit: 'Digging time',
    standort: 'Location',
    merkmale: 'Characteristics',
    funde: 'Finds',
    kategorie: 'Category',
    koopzeitraum: 'Co-op period',
    beteiligte: 'Involved'
  }
};
module.exports.meta = meta;

const Kategorie = [
  {
    id: 'grab',
    title_de: 'Grab',
    title_en: 'Grave'
  },
  {
    id: 'hafen',
    title_de: 'Hafen',
    title_en: 'Port'
  },
  {
    id: 'hort',
    title_de: 'Hort',
    title_en: 'Hoard'
  },
  {
    id: 'lager',
    title_de: 'Lager',
    title_en: 'Camp'
  },
  {
    id: 'siedlung',
    title_de: 'Siedlung',
    title_en: 'Settlement'
  },
  {
    id: 'opferplatz',
    title_de: 'Opferplatz',
    title_en: 'Sacrificial site'
  },
  {
    id: 'weg',
    title_de: 'Weg',
    title_en: 'Path'
  },
  {
    id: 'wehranlage',
    title_de: 'Wehranlage',
    title_en: 'Fortification'
  },
  {
    id: 'wrack',
    title_de: 'Wrack',
    title_en: 'Wreck'
  },
  {
    id: 'anderes',
    title_de: 'Anderes',
    title_en: 'Other'
  }
];

module.exports.Kategorie = Kategorie;

const Funde = [
  {
    id: 'kreamik',
    title_de: 'Keramik/Gefäße',
    title_en: 'Pottery/Vessels'
  },
  {
    id: 'hausebestand',
    title_de: 'Hausbestandteile',
    title_en: 'Home components'
  },
  {
    id: 'kleidung',
    title_de: 'Kleidung',
    title_en: 'Clothing'
  },
  {
    id: 'knochen',
    title_de: 'Knochen',
    title_en: 'Bones'
  },
  {
    id: 'muenzen',
    title_de: 'Münzen',
    title_en: 'Coins'
  },
  {
    id: 'nahrung',
    title_de: 'Nahrung',
    title_en: 'Food'
  },
  {
    id: 'schmuck',
    title_de: 'Schmuck',
    title_en: 'Jewellery'
  },
  {
    id: 'waffen',
    title_de: 'Waffen',
    title_en: 'Weapons'
  },
  {
    id: 'werkzeug',
    title_de: 'Werkzeuge',
    title_en: 'Tools'
  },
  {
    id: 'andere',
    title_de: 'Anderes',
    title_en: 'Other'
  }
];

module.exports.Funde = Funde;

<template>
  <div v-if="isValid" class="header">
    <transition name="fade">
      <img :key="image" class="headerImage" :src="'../' + image" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'HeaderImage',
  computed: {
    HeaderImage() {
      return this.$store.state.activeContentBlock.headerImage;
    },
    isValid() {
      return this.HeaderImage !== undefined;
    },
    image() {
      const path = this.HeaderImage[0].path;
      const name = this.HeaderImage[0].name;
      const type = this.HeaderImage[0].type;

      return path + name + '_export.' + type;
    }
  }
};
</script>

<style scoped>
.headerImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
}
.header {
  position: relative;
  height: 330px;
  width: 100%;
}
</style>

const COLORS = {
  kap00: '#3498DB',
  kap01: '#2980B9',
  kap02: '#324CAA',
  kap03: '#4b32c0',
  kap04: '#9A349C',
  kap05: '#AF2070',
  kap06: '#F62E2E',
  kap07: '#E74C3C',
  kap08: '#FF7503',
  kap09: '#E67E22'
};
module.exports.COLORS = COLORS;

<template>
  <div v-if="isValid" class="header" @click="openLightBox()">
    <transition name="fade">
      <img
        :key="image"
        class="headerImage"
        :class="{ blur: !isLoaded }"
        :src="'../' + image"
        :style="{
          objectPosition: '50% ' + posY,
          backgroundImage: `url(${thumb})`,
          backgroundPosition: '50% ' + posY
        }"
        @load="loaded"
      />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'GalleryImage',
  props: {
    activeImage: Number
  },
  data() {
    return {
      isLoaded: false
    };
  },
  computed: {
    activeImageData() {
      const gallery = this.$store.state.activeContentBlock.gallery;
      if (gallery) {
        return this.$store.state.activeContentBlock.gallery[this.activeImage];
      }
      return null;
    },
    isValid() {
      return this.activeImageData !== null;
    },
    image() {
      const path = this.activeImageData.path;
      const name = this.activeImageData.name;
      const type = this.activeImageData.type;

      return path + name + '_export.' + type;
    },
    thumb() {
      const path = this.activeImageData.path;
      const name = this.activeImageData.name;
      const type = this.activeImageData.type;
      return '../' + path + name + '_crop.' + type;
    },
    posY() {
      return this.activeImageData.focusY * 100 + '%';
    }
  },
  methods: {
    openLightBox() {
      this.$emit('openLightBox', true);
    },
    loaded() {
      this.isLoaded = true;
    }
  }
};
</script>

<style scoped>
.headerImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  background-size: cover;
}
.header {
  position: relative;
  height: 330px;
  width: 100%;
  cursor: pointer;
  background-color: #f7f7f7;
  overflow: hidden;
  margin-bottom: 13px;
}
.blur {
  filter: blur(10px);
}
</style>

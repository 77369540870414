<template>
  <div class="block">
    <div class="kapitelHeader">{{ title }}</div>
    <div class="kapitelLines">
      <div
        v-for="entry in entrys"
        v-bind:style="{
          color: colorPalette()
        }"
        :class="{
          notInActiveFilter: notInActiveFilter(entry.id),
          inFilter: entry.isInFilter
        }"
        class="kapitelHeadlines"
        :key="entry.id"
        @click="click(entry.id)"
      >
        {{ getTranslation(entry) }}
      </div>
    </div>
  </div>
</template>

<script>
import Globals from '../../services/globals';
export default {
  name: 'MobileKapitel',
  props: {
    title: String,
    filter: {
      type: String,
      default: 'kap01'
    }
  },
  data: function () {
    return {
      timeoutA: Object
    };
  },
  computed: {
    entrys() {
      return this.$store.state.filterContent.filter(
        (kapitel) => kapitel.kapitel === this.filter
      );
    }
  },
  methods: {
    getTranslation(entry) {
      if (this.$store.state.activeLng === 'de') {
        return entry.title_de;
      }
      return entry.title_en;
    },
    click(searchID) {
      this.$store.dispatch('setActiveContentBlock', searchID);
      this.$emit('close');
    },
    colorPalette() {
      return Globals.COLORS[this.filter];
    },
    notInActiveFilter() {
      return this.$store.state.filter !== '';
    }
  }
};
</script>

<style scoped>
.kapitelHeader {
  font-size: 12px;
  line-height: 1.3em;
  color: rgb(66 66 66);
  margin-bottom: 1em;
  margin-top: 2em;
  border-bottom: solid 1px;
}
.kapitelHeadlines {
  margin-bottom: 1em;
  font-size: 14px;
}
.notInActiveFilter {
  opacity: 0.5;
}
.inFilter {
  font-weight: bold;
  opacity: 1;
}
</style>

<template>
  <div class="imageContainer" v-if="isValid">
    <div v-for="(image, index) in metaImage" :key="image.name">
      <img :src="'../' + getImage(index)" :id="getImage(index)" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'MiniMap',
  computed: {
    metaImage() {
      return this.$store.state.activeContentBlock.miniMap;
    },
    isValid() {
      return this.metaImage !== undefined;
    }
  },
  methods: {
    getImage(index) {
      const path = this.metaImage[index].path;
      const name = this.metaImage[index].name;
      const type = this.metaImage[index].type;

      return path + name + '_export.' + type;
    }
  }
};
</script>

<style scoped>
.imageContainer {
  position: relative;
  background-repeat: no-repeat;
  margin-left: 1em;
  width: 90%;
  padding-bottom: 90%;
}
img {
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="artikel">
    <h1 v-bind:style="{ color: keyColor }">{{ Headline }}</h1>
    <h2 v-bind:style="{ color: keyColor }">{{ Subline }}</h2>
    <div class="fliestext" v-html="Paragraph"></div>
    <div class="caption" v-html="Literatur"></div>
  </div>
</template>

<script>
import Globals from '../../services/globals.js';
export default {
  name: 'Textblock',
  computed: {
    ActiveContentBlock() {
      this.resetPostion();
      return this.$store.state.activeContentBlock.text;
    },
    Headline() {
      return this.ActiveContentBlock.headline;
    },
    Subline() {
      return this.ActiveContentBlock.subline;
    },
    Paragraph() {
      return this.ActiveContentBlock.artikel;
    },
    Literatur() {
      return this.ActiveContentBlock.literatur;
    },
    keyColor() {
      return Globals.COLORS[this.$store.state.activeContentBlock.kapitel];
    }
  },
  methods: {
    resetPostion() {
      if (document.getElementsByClassName('artikel')[0]) {
        document.getElementsByClassName('artikel')[0].scrollTop = 0;
      }
    }
  }
};
</script>

<style scoped>
.artikel {
  padding-top: 38px;
}

h1 {
  font-weight: bold;
  font-size: 40px;
  line-height: 44px;
  margin: 0;
  max-width: 724px;
  word-break: break-word;
}

h2 {
  font-weight: normal;
  font-size: 40px;
  line-height: 44px;
  margin: 0 0 30px 0;
  max-width: 724px;
  opacity: 0.6;
  word-break: break-word;
}

.fliestext {
  font-size: 16px;
  line-height: 25px;
  max-width: 724px;
}

.caption {
  font-size: 12px;
  line-height: 18px;
  max-width: 724px;
  word-break: break-word;
}
</style>

<template>
  <div class="lightBox" @click.self="close">
    <transition :name="swipe" mode="out-in">
      <div class="contentBox" :key="image">
        <div class="Content">
          <img class="mainImage" :src="'../' + image" />
          <canvas
            id="imagePlaceHolder"
            :width="size.x"
            :height="size.y"
          ></canvas>
          <div class="caption" v-html="caption"></div>
        </div>
        <div class="pre" @click="pre">
          <img
            class="icons pres"
            src="../../assets/icons/chevron-back-outline.svg"
          />
        </div>
        <div class="close" @click="close" @keyup.enter="close" tabindex="0">
          <img class="icons" src="../../assets/icons/close-outline.svg" />
        </div>
        <div class="next" @click="next">
          <img
            class="icons"
            src="../../assets/icons/chevron-forward-outline.svg"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LightBox',
  data: function () {
    return {
      Image: 0,
      swipe: 'swipeLeft'
    };
  },
  props: {
    activeImage: Number
  },
  created: function () {
    window.addEventListener('keyup', this.eventHandler);
    this.Image = this.activeImage;
  },
  computed: {
    gallery() {
      return this.$store.state.activeContentBlock.gallery;
    },
    galleryCount() {
      return this.gallery.length;
    },
    caption() {
      return this.gallery[this.Image].caption;
    },
    size() {
      return {
        x: this.gallery[this.Image].sizeX,
        y: this.gallery[this.Image].sizeY
      };
    },
    image() {
      const path = this.gallery[this.Image].path;
      const name = this.gallery[this.Image].name;
      const type = this.gallery[this.Image].type;
      return path + name + '_export.' + type;
    }
  },
  methods: {
    eventHandler(event) {
      if (event.code === 'Escape') {
        return this.$emit('close');
      }
      if (event.code === 'ArrowRight') {
        this.next();
      }
      if (event.code === 'ArrowLeft') {
        this.pre();
      }
    },
    next() {
      this.swipe = 'swipeLeft';

      this.Image = this.mod(this.Image + 1);
    },
    pre() {
      this.swipe = 'swipeRight';
      this.Image = this.mod(this.Image - 1);
    },
    close() {
      this.$emit('close');
    },
    mod(n) {
      return ((n % this.galleryCount) + this.galleryCount) % this.galleryCount;
    }
  }
};
</script>

<style scoped>
.lightBox {
  position: fixed;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: #00000094;
  left: 0;
  top: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentBox {
  width: min-content;
  display: grid;
  grid-template-columns: 0.2fr 2.6fr 0.2fr;
  grid-template-rows: 2em 1fr;
  gap: 0 0;
  grid-template-areas:
    'mClose null Header'
    'Left Center Right';
}
.Content {
  grid-area: Center;
  position: relative;
}
.mainImage {
  width: 100%;
  margin-bottom: 0;
  background-color: #f7f7f7;
  position: absolute;
}
canvas {
  max-width: 75vw;
  max-height: 75vh;
  margin-bottom: 0;
  background-color: #f7f7f7;
}
.icons {
  height: 2em;
}
.close {
  grid-area: Header;
  justify-self: flex-start;
  align-self: flex-end;
  margin: 0;
}
.next {
  display: grid;
  grid-area: Right;
  justify-self: center;
  align-self: center;
  justify-content: start;
  align-items: center;
  width: 6em;
  height: 6em;
}
.pre {
  display: grid;
  grid-area: Left;
  justify-self: center;
  align-self: center;
  justify-content: end;
  align-items: center;
  width: 6em;
  height: 6em;
}
.caption {
  margin-top: -1px;
  padding: 10px;
  background-color: white;
  line-height: 120%;
}
</style>

<template>
  <div class="filterBox" :style="{ color: colorHigh }">
    <h4 class="label">{{ label }}</h4>
    <ul class="tag">
      <li
        class="textstyle_metaText"
        v-for="(element, id) in list"
        :style="{ color: setColor(state[id]) }"
        :key="element + id"
        :class="{
          active: state[id],
          searchItem: isFilter(element.id)
        }"
        @click="click(element.id)"
      >
        {{ getTranslation(element) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FilterBox',
  props: {
    label: String,
    list: Object,
    state: Array
  },
  computed: {
    colorHigh() {
      return this.$store.state.colorHigh;
    },
    colorLow() {
      return this.$store.state.colorLow;
    }
  },
  methods: {
    click(filter) {
      if (filter === this.$store.state.filter) {
        filter = '';
      }
      this.$store.dispatch('setFilter', filter);
    },
    isFilter(id) {
      return id === this.$store.state.filter;
    },
    setColor(type) {
      if (type) {
        return this.colorLow;
      } else {
        return this.colorHigh;
      }
    },
    getTranslation(element) {
      if (this.$store.state.activeLng === 'de') {
        return element.title_de;
      }
      return element.title_en;
    }
  }
};
</script>

<style scoped>
p {
  margin-top: 2px;
  font-weight: 500;
}

.searchItem {
  /*text-decoration: underline;*/
}

.searchItem:before {
  content: '\e800   ';
  font-family: 'fontello';
  margin-left: -1.2em;
  font-size: 1em;
  line-height: 0;
}

.label {
  margin-bottom: 0;
}

ul {
  list-style-type: none;
  padding: 0;
  margin-top: 2px;
}

li {
  margin-top: 4px;
  opacity: 0.5;
  letter-spacing: 0.03em;
  cursor: pointer;
}

.active {
  opacity: 1;
  font-weight: 700;
  letter-spacing: 0.03em;
}

.filterBox {
  margin-left: 1em;
}
</style>

const radius = 6371;

function calcMapProps(p1, p2) {
  const averageLatitudes = p1.lat + p2.lat;

  let minLong = radius * p1.long * Math.cos(averageLatitudes / 2);
  let minLat = radius * p1.lat;

  let maxLong = radius * p2.long * Math.cos(averageLatitudes / 2);
  let maxLat = radius * p2.lat;

  return {
    minLong: minLong,
    minLat: minLat,
    maxLong: maxLong,
    maxLat: maxLat,
    averageLatitudes: averageLatitudes
  };
}

function latToY(lat, MapProps) {
  const y = 6371 * lat;
  return (
    ((y - MapProps.minLat) / (MapProps.maxLat - MapProps.minLat)) * 100 + '%'
  );
}

function longToX(long, MapProps) {
  const x = 6371 * long * Math.cos(MapProps.averageLatitudes / 2);
  return (
    ((x - MapProps.minLong) / (MapProps.maxLong - MapProps.minLong)) * 100 + '%'
  );
}

export { calcMapProps, latToY, longToX };

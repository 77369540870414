<template>
  <div
    v-if="isValid"
    ref="miniMap"
    class="dotContainter"
    :key="getMapView"
    :class="getMapViewCSS"
  >
    <img
      class="zoomIn zoomBox"
      src="../../../assets/icons/plus.svg"
      alt="zoomIn"
      @click="zoomIn"
      @touchstart="zoomIn"
    />
    <img
      class="zoomOut zoomBox"
      src="../../../assets/icons/minus.svg"
      alt="zoomOut"
      @click="zoomOut"
      @touchstart="zoomOut"
    />
    <div
      v-for="item in dots"
      :key="item.id + '_dots'"
      class="dot"
      v-bind:style="{
        left: longToX(item.map.lon),
        top: latToY(item.map.lat),
        borderColor: setColor(item.kapitel),
        backgroundColor: setColor(item.kapitel)
      }"
      v-bind:class="{
        aktiveDot: isActiveBlock(item.id),
        previewDot: isPreviewBlock(item.id),
        notInActiveFilter: notInActiveFilter(item.id),
        inFilter: item.isInFilter
      }"
      @click="click(item.id)"
      @touchstart="click(item.id)"
      @mouseover="mouseOver(item.id)"
      @mouseleave="mouseOut()"
    ></div>
  </div>

  <div v-if="!isValid && isNoSystem" class="dotContainter emtpyMap"></div>
</template>

<script>
import Globals from '../../../services/globals.js';
import { calcMapProps, latToY, longToX } from '../../../services/mapConversion';

export default {
  name: 'map.vue',
  props: {},
  data: function () {
    return {
      mobileScreen: false
    };
  },
  created() {
    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    this.mobileScreen = window.innerWidth <= 1024;
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    isNoSystem() {
      return this.$store.state.activeContentBlock.type !== 'system';
    },
    activeContentBlock() {
      return this.$store.state.activeContentBlock;
    },
    isValid() {
      if (this.activeContentBlock.map === undefined) {
        return false;
      }
      return this.activeContentBlock.map.viewSize !== '';
    },
    getMapView() {
      return this.$store.state.mapView;
    },
    getMapViewCSS() {
      const view = this.getMapView;
      if (view === 'verynear') {
        if (this.$store.state.activeLng === 'de') {
          return 'verynear_DE';
        }
        return 'verynear_EN';
      }
      if (view === 'near') {
        if (this.$store.state.activeLng === 'de') {
          return 'near_DE';
        }
        return 'near_EN';
      }
      return 'far';
    },
    dots() {
      const allDots = this.$store.state.filterContent;
      return allDots.filter((viewSize) => viewSize.map.lat !== '');
    },
    p0() {
      if (this.getMapView === 'near') {
        return { lat: 55.210076, long: 8.126629 };
      }
      if (this.getMapView === 'far') {
        return { lat: 95, long: -19 };
      }
      return { lat: 54.624092, long: 9.336941 };
    },
    p1() {
      if (this.getMapView === 'near') {
        return { lat: 53.265059, long: 11.455486 };
      }
      if (this.getMapView === 'far') {
        return { lat: -38, long: 138 };
      }
      return { lat: 54.370479, long: 9.774334 };
    },
    mapProps() {
      return calcMapProps(this.p0, this.p1);
    }
  },
  methods: {
    zoomIn() {
      if (this.getMapView === 'far') {
        this.$store.dispatch('setZoomLevel', 'near');
      } else if (this.getMapView === 'near') {
        this.$store.dispatch('setZoomLevel', 'verynear');
      }
    },
    zoomOut() {
      if (this.getMapView === 'verynear') {
        this.$store.dispatch('setZoomLevel', 'near');
      } else if (this.getMapView === 'near') {
        this.$store.dispatch('setZoomLevel', 'far');
      }
    },
    mouseOver(searchID) {
      if (!this.mobileScreen) {
        this.$store.dispatch('previewContentBlock', searchID);
      }
    },
    mouseOut() {
      if (!this.mobileScreen) {
        this.$store.dispatch('endPreviewContentBlock');
      }
    },
    click(searchID) {
      this.$store.dispatch('setActiveContentBlock', searchID);
    },
    setColor(kapitel) {
      return Globals.COLORS[kapitel];
    },
    isActiveBlock(id) {
      const cleanID = id.split('_i')[0];
      const cleanStoreID = this.$store.state.id.split('_i')[0];
      return cleanID === cleanStoreID;
    },
    isPreviewBlock(id) {
      const cleanID = id.split('_i')[0];
      const cleanStoreID = this.$store.state.activeContentBlock.id.split(
        '_i'
      )[0];
      return cleanID === cleanStoreID;
    },
    longToX(long) {
      return longToX(long, this.mapProps);
    },
    latToY(lat) {
      return latToY(lat, this.mapProps);
    },
    notInActiveFilter() {
      return this.$store.state.filter !== '';
    },
    onResize() {
      this.mobileScreen = window.innerWidth <= 1024;
    }
  }
};
</script>

<style scoped>
.dot {
  position: absolute;
  width: 6px;
  height: 6px;
  margin-top: -3px;
  margin-left: -3px;
  border-radius: 1000%;
  opacity: 0.4;
  cursor: pointer;
}
.dot:hover {
  opacity: 1;
}

.previewDot {
  opacity: 1;
}

.previewDot::after {
  content: ' ';
  width: 14px;
  height: 14px;
  position: absolute;
  border: solid;
  border-color: inherit;
  border-width: 1px;
  border-radius: 1000px;
  transform: translate(-5px, -5px);
}

.aktiveDot::after {
  content: ' ';
  width: 14px;
  height: 14px;
  position: absolute;
  border: solid;
  border-color: inherit;
  border-width: 1px;
  border-radius: 1000px;
  transform: translate(-3px, -3px);
}

.aktiveDot {
  opacity: 1;
  width: 10px;
  height: 10px;
  margin-top: -5px;
  margin-left: -5px;
}

.notInActiveFilter {
  opacity: 0.1;
}

.inFilter {
  opacity: 1;
}

.dotContainter {
  position: relative;
  background-repeat: no-repeat;
  margin-left: 1em;
  width: 90%;
  padding-bottom: 90%;
  overflow: hidden;
}

.verynear_DE {
  background-image: url('../../../../public/map/karte_schleswig.svg');
  background-size: 100%;
}
.near_DE {
  background-image: url('../../../../public/map/karte_sh.svg');
  background-size: 100%;
}
.far {
  background-image: url('../../../../public/map/karte_weltweit_Clean.svg');
  background-size: 100%;
}

.verynear_EN {
  background-image: url('../../../../public/map/karte_schleswig_en.svg');
  background-size: 100%;
}
.near_EN {
  background-image: url('../../../../public/map/karte_sh_en.svg');
  background-size: 100%;
}

.emtpyMap {
  background-image: url('../../../../public/map/karte_sh.svg');
  background-size: 100%;
  opacity: 0.1;
}

input {
  width: 300px;
}

.zoomBox {
  position: absolute;
  width: 1em;
  height: 1em;
  background-color: #bdbdbd7a;
  padding: 4px;
  z-index: 1;
  border-radius: 1000px;
  transition: all 500ms;
}

.zoomBox:hover {
  transform: scale(1.05);
  transition: all 500ms;
}

.zoomIn {
  margin-top: 0;
  right: 0;
}

.zoomOut {
  margin-top: 2em;
  right: 0;
}
</style>

<template>
  <div class="imageContainer" v-if="isValid">
    <h4 class="label" v-bind:style="{ color: colorHigh }">Logos</h4>
    <div class="logos">
      <img
        v-for="(image, index) in metaImage"
        :key="image.name"
        :src="'../' + getImage(index)"
        :id="getImage(index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Logos',
  computed: {
    metaImage() {
      return this.$store.state.activeContentBlock.logos;
    },
    isValid() {
      return this.metaImage !== undefined;
    },
    colorHigh() {
      return this.$store.state.colorHigh;
    },
    colorLow() {
      return this.$store.state.colorLow;
    }
  },
  methods: {
    getImage(index) {
      const path = this.metaImage[index].path;
      const name = this.metaImage[index].name;
      const type = this.metaImage[index].type;

      return path + name + '_export.' + type;
    }
  }
};
</script>

<style scoped>
.label {
  margin-bottom: 0;
}

.imageContainer {
  position: relative;
  margin-left: 1em;
}
.logos {
  display: flex;
  flex-wrap: wrap;
}
img {
  height: 4em;
  margin: 0.5em;
}
</style>

import { createStore } from 'vuex';

import {
  FullContent,
  FullContentEN,
  FilterContent
} from '../services/dataPreprocess';
import Globals from '../services/globals';
import router from '../router';

export default createStore({
  state: {
    content: FullContent,
    filterContent: FilterContent,
    activeContentBlock: Object,
    filter: String,
    id: String,
    mapView: '',
    activeColor: String,
    activeLng: 'de'
  },
  mutations: {
    SET_TRANSLATION(state, id) {
      if (state.activeLng !== id) {
        state.activeLng = id;
        if (id === 'en') {
          state.content = FullContentEN;
        } else {
          state.content = FullContent;
        }
        state.activeContentBlock = this.state.content.find(
          (artkel) => artkel.id === state.id
        );
      }
    },
    SET_MAPVIEW(state, id) {
      state.mapView = id;
    },
    SET_ACTIVECONTENTBLOCK(state, id) {
      if (id) {
        state.activeContentBlock = this.state.content[0];
      }
      state.activeContentBlock = this.state.content.find(
        (artkel) => artkel.id === id
      );

      const kap = state.activeContentBlock.kapitel;
      state.activeColor = Globals.COLORS[kap];
    },
    STORE_CONTENTBLOCKID(state, id) {
      state.id = id;
    },
    SET_AKTIVEIMAGE(state, number) {
      state.activeImage = number;
    },
    SET_FILTER(state, filter) {
      state.filter = filter;

      for (const filterContentElement of state.filterContent) {
        filterContentElement.isInFilter = filterContentElement.tag.includes(
          filter
        );
      }
    }
  },
  actions: {
    toogleTranslation({ commit }) {
      if (this.state.activeLng === 'de') {
        commit('SET_TRANSLATION', 'en');
        router.push({ name: 'en', params: { id: this.state.id } });
      } else {
        commit('SET_TRANSLATION', 'de');
        router.push({ name: 'de', params: { id: this.state.id } });
      }
    },
    setActiveImage({ commit }, number) {
      commit('SET_AKTIVEIMAGE', number);
    },
    setActiveContentBlock({ commit }, id) {
      commit('STORE_CONTENTBLOCKID', id);
      commit('SET_ACTIVECONTENTBLOCK', id);
      const mapViewState = this.state.activeContentBlock.map;
      if (mapViewState) {
        commit('SET_MAPVIEW', mapViewState.viewSize);
      }
      commit('SET_FILTER', '');
      router.push(id);
    },
    previewContentBlock({ commit }, id) {
      commit('SET_ACTIVECONTENTBLOCK', id);
    },
    previewContentBlockSideBar({ commit }, id) {
      commit('SET_ACTIVECONTENTBLOCK', id);
      commit('SET_MAPVIEW', this.state.activeContentBlock.map.viewSize);
    },
    endPreviewContentBlock({ commit }) {
      commit('SET_ACTIVECONTENTBLOCK', this.state.id);
      commit('SET_MAPVIEW', this.state.activeContentBlock.map.viewSize);
    },
    setFilter({ commit }, filter) {
      commit('SET_FILTER', filter);
    },
    setZoomLevel({ commit }, zoomLevel) {
      commit('SET_MAPVIEW', zoomLevel);
    }
  }
});

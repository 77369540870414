<template>
  <div class="header">
    <div class="headline">{{ lng.titel }}</div>
    <div class="infoBox">
      <div class="button" @click="click('info')">{{ lng.info }}</div>
      <div class="button" @click="click('datenschutz')">
        {{ lng.datenschutz }}
      </div>
      <div class="button" @click="click('impressum')">{{ lng.impressum }}</div>
      <div :class="getLng" class="button lngSelect" @click="toogleTranslation">
        <span>DEU</span>
        <span> - </span>
        <span>ENG</span>
      </div>
    </div>
  </div>
</template>

<script>
import { header } from '../../services/translation';

export default {
  name: 'Header',
  computed: {
    getLng() {
      return this.$store.state.activeLng;
    },
    lng() {
      if (this.getLng === 'de') {
        return header.de;
      }
      return header.en;
    }
  },
  methods: {
    click(searchID) {
      this.$store.dispatch('setActiveContentBlock', searchID);
    },
    toogleTranslation() {
      this.$store.dispatch('toogleTranslation');
    }
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.headline {
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #b2b2b2;
  margin-left: 3em;
}

@media only screen and (max-width: 480px) {
  .headline {
    margin: 0;
  }
  .header {
    width: unset;
    margin: 10px;
  }
}

.infoBox {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
  max-width: 350px;
  width: 100%;
  margin-right: 30px;
}
.button {
  /*margin-right: 2em;*/
  color: #b2b2b2;
  cursor: pointer;
}

.button:hover {
  color: #4b4b4b;
}

.lngSelect {
  text-align: right;
}

.de span:nth-child(1) {
  color: #4b4b4b;
}
.de span:nth-child(3) {
  color: #b2b2b2;
}

.en span:nth-child(1) {
  color: #b2b2b2;
}
.en span:nth-child(3) {
  color: #4b4b4b;
}
</style>

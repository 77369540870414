<template>
  <div v-if="isValid" class="thumbnails" :key="thumbkey">
    <div v-for="(image, index) in gallery" :key="thumbkey + image">
      <img
        class="thumbnail"
        :src="'../' + getThumbnail(index)"
        :id="getThumbnail(index)"
        @mouseover="mouseover(index)"
        @click="openLightBox(index)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Thumbnails',
  computed: {
    gallery() {
      return this.$store.state.activeContentBlock.gallery;
    },
    isValid() {
      return this.gallery !== undefined;
    },
    thumbkey() {
      return this.$store.state.activeContentBlock.id + '_thumb';
    }
  },
  methods: {
    getThumbnail(index) {
      const path = this.gallery[index].path;
      const name = this.gallery[index].name;
      const type = this.gallery[index].type;

      return path + name + '_crop.' + type;
    },
    mouseover(index) {
      this.$emit('setActiveImage', index);
    },
    openLightBox() {
      this.$emit('openLightBox', true);
    }
  }
};
</script>

<style scoped>
.thumbnails {
  position: sticky;
  top: 0;
  width: 100%;
  background: white;
  max-width: 100%;
  z-index: 1;

  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  justify-content: space-between;
  grid-gap: 10px;

  padding-bottom: 10px;
}

.thumbnail {
  background-color: #f7f7f7;

  width: 100px;
  height: 65px;
  cursor: pointer;
}
</style>

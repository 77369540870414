<template>
  <InfoBox v-if="showPanel" label="beteiligte" :content="content" />
</template>

<script>
import InfoBox from '../InfoBox';
import { meta } from '../../../services/translation';

export default {
  name: 'Beteiligte',
  components: { InfoBox },
  computed: {
    content() {
      return this.$store.state.activeContentBlock.meta?.beteiligte;
    },
    showPanel() {
      return this.content !== undefined;
    },
    getLabel() {
      if (this.$store.state.activeLng === 'de') {
        return meta.de.beteiligte;
      }
      return meta.en.beteiligte;
    }
  }
};
</script>

<style scoped></style>

<template>
  <div class="block">
    <div class="kapitelHeader">{{ title }}</div>
    <div class="lines" @mouseleave="mouseOut()">
      <div
        v-for="entry in entrys"
        class="box"
        :class="{
          notInActiveFilter: notInActiveFilter(entry.id),
          inFilter: entry.isInFilter,
          active: isActiveBlock(entry.id)
        }"
        v-bind:style="{
          backgroundColor: colorPalette()
        }"
        :key="entry.id"
        @click="click(entry.id)"
        @mouseover="mouseOver(entry.id)"
        @touchstart="click(entry.id)"
      ></div>
    </div>
  </div>
</template>

<script>
import Globals from '../../services/globals';
export default {
  name: 'KapitelBlock',
  props: {
    title: String,
    filter: {
      type: String,
      default: 'kap01'
    }
  },
  data: function () {
    return {
      timeoutA: Object
    };
  },
  computed: {
    entrys() {
      return this.$store.state.filterContent.filter(
        (kapitel) => kapitel.kapitel === this.filter
      );
    }
  },
  methods: {
    isActiveBlock(id) {
      const cleanID = id.split('_i')[0];
      const cleanStoreID = this.$store.state.id.split('_i')[0];
      return cleanID === cleanStoreID;
    },
    click(searchID) {
      this.$store.dispatch('setActiveContentBlock', searchID);
    },
    mouseOver(searchID) {
      clearTimeout(this.timeoutA);
      this.timeoutA = setTimeout(() => {
        this.$store.dispatch('previewContentBlockSideBar', searchID);
      }, 50);
    },
    mouseOut() {
      setTimeout(() => {
        this.$store.dispatch('endPreviewContentBlock');
      }, 51);
    },
    colorPalette() {
      return Globals.COLORS[this.filter];
    },
    notInActiveFilter() {
      return this.$store.state.filter !== '';
    }
  }
};
</script>

<style scoped>
.lines {
  float: right;
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
  width: 40%;
}

.box {
  padding: 2px;
  width: 95%;
  height: 3px;
  background-clip: content-box;
  opacity: 0.5;
  cursor: pointer;

  transition: all 250ms;
}

.box:hover {
  opacity: 1;
}

.kapitelHeader {
  box-sizing: border-box;
  width: 40%;
  margin-right: 20px;
  text-align: end;
  font-size: 12px;
  line-height: 1.3em;
  color: rgb(178, 178, 178);
  white-space: pre-line;
}

.block {
  display: flex;
  margin: 0 auto;
}

.active {
  width: 100%;
  opacity: 1;
}

.notInActiveFilter {
  opacity: 0.1;
}

.inFilter {
  width: 97%;
  opacity: 1;
}
</style>

<template>
  <InfoBox v-if="showPanel" :label="getLabel" :content="content" />
</template>

<script>
import InfoBox from '../InfoBox';
import { meta } from '../../../services/translation';

export default {
  name: 'Merkmale',
  components: { InfoBox },
  computed: {
    content() {
      return this.$store.state.activeContentBlock.meta?.merkmale;
    },
    showPanel() {
      return this.content !== undefined;
    },
    getLabel() {
      if (this.$store.state.activeLng === 'de') {
        return meta.de.merkmale;
      }
      return meta.en.merkmale;
    }
  }
};
</script>

<style scoped></style>

<template>
  <div class="home grid-container">
    <Header class="Header" />
    <MobileNavBar v-if="isMobile" />
    <NavBar class="Sidebar" v-if="!isMobile" />
    <div v-if="isNoSystem" class="back" @click="click('down')">
      <img src="../assets/icons/back.svg" alt="back" />
    </div>
    <transition name="miniSwipe">
      <Artikel class="Artikel" />
    </transition>
    <div v-if="isNoSystem" class="next" @click="click('up')">
      <img src="../assets/icons/next.svg" alt="next" />
    </div>
    <MetaBar class="Meta" :key="keyGen + 'meta'" />
  </div>
</template>

<script>
import Header from '../components/Layout/Header.vue';
import NavBar from '../components/Layout/Sidebar';
import Artikel from '../components/Layout/Artikel';
import MetaBar from '../components/Layout/MetaBar';
import MobileNavBar from '../components/Layout/MobileNavBar';

export default {
  components: { MobileNavBar, MetaBar, Artikel, Header, NavBar },
  name: 'Home',
  created() {
    let searchID = this.$route.params.id;
    if (searchID) {
      this.$store.dispatch('setActiveContentBlock', searchID);
    }
    if (this.$route.name === 'en') {
      this.$store.dispatch('toogleTranslation');
    }
    window.addEventListener('resize', this.onResize);
  },
  data: function () {
    return {
      mobileScreen: false
    };
  },
  computed: {
    isNoSystem() {
      return this.$store.state.activeContentBlock.type !== 'system';
    },
    isMobile() {
      return this.mobileScreen;
    },
    keyGen() {
      return this.$store.state.activeContentBlock.id;
    }
  },
  mounted() {
    this.mobileScreen = window.innerWidth <= 1024;
  },
  unmounted() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.mobileScreen = window.innerWidth <= 1024;
    },
    click(upDown) {
      const contentList = this.$store.state.filterContent;
      const maxCount = contentList.length;

      let idArray = [];
      for (const elemnt of contentList) {
        idArray.push(elemnt.kapitel);
      }

      const mapped = idArray.map(function (el, i) {
        return { index: i, value: el.toLowerCase() };
      });

      mapped.sort(function (a, b) {
        if (a.value > b.value) {
          return 1;
        }
        if (a.value < b.value) {
          return -1;
        }
        return 0;
      });

      // Behälter für die sich ergebende Reihenfolge
      const result = mapped.map(function (el) {
        return contentList[el.index];
      });

      let aktiveContentNum = result.findIndex(
        (element) => element.id === this.$store.state.id
      );

      if (upDown === 'up') {
        aktiveContentNum = aktiveContentNum + 1;
      } else {
        aktiveContentNum = aktiveContentNum - 1;
      }

      const nextNumber = this.mod(aktiveContentNum, maxCount);

      const newID = result[nextNumber].id;

      this.$store.dispatch('setActiveContentBlock', newID);
    },
    mod(n, m) {
      return ((n % m) + m) % m;
    }
  }
};
</script>

<style scoped>
.grid-container {
  background-color: #ffffff;
  max-width: 1920px;
  min-height: 1000px;
  max-height: calc(100vh - 20px);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 6em 1fr;
  gap: 0;
  grid-template-areas:
    'Header Header Header'
    'Sidebar Content Meta';
  align-items: start;
}

.Header {
  background-color: #fff;
  grid-area: Header;
}

.Sidebar {
  background-color: #fff;
  grid-area: Sidebar;
}
.back {
  grid-area: Content;
  justify-self: left;
  align-self: center;
  height: 2em;
  width: 2em;
  opacity: 0.1;
  cursor: pointer;
}
.back:hover {
  opacity: 0.3;
}
.next {
  grid-area: Content;
  justify-self: right;
  align-self: center;
  height: 2em;
  width: 2em;
  opacity: 0.1;
  cursor: pointer;
}
.next:hover {
  opacity: 0.3;
}

@media only screen and (max-width: 1024px) {
  .grid-container {
    max-height: unset;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'Header Header'
      'Content Meta';
  }
  .Sidebar {
    display: none;
  }
  .back {
    display: none;
  }
  .next {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .grid-container {
    max-height: unset;
    grid-template-columns: 1fr;
    grid-template-areas:
      'Header'
      'Content'
      'Meta';
  }
  .back {
    display: none;
  }
  .next {
    display: none;
  }
}
</style>

<template>
  <div>
    <KapitelBlock :title="lng.kap00" filter="kap00" />
    <KapitelBlock :title="lng.kap01" filter="kap01" />
    <KapitelBlock :title="lng.kap02" filter="kap02" />
    <KapitelBlock :title="lng.kap03" filter="kap03" />
    <KapitelBlock :title="lng.kap04" filter="kap04" />
    <KapitelBlock :title="lng.kap05" filter="kap05" />
    <KapitelBlock :title="lng.kap06" filter="kap06" />
    <KapitelBlock :title="lng.kap07" filter="kap07" />
    <KapitelBlock :title="lng.kap08" filter="kap08" />
    <KapitelBlock :title="lng.kap09" filter="kap09" />
  </div>
</template>

<script>
import KapitelBlock from '../Elements/KapitelBlock';
import { sidebar } from '../../services/translation';

export default {
  name: 'Sidebar',
  components: { KapitelBlock },
  computed: {
    lng() {
      if (this.$store.state.activeLng === 'de') {
        return sidebar.de;
      }
      return sidebar.en;
    }
  }
};
</script>

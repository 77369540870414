<template>
  <FilterBox
    v-if="isValid"
    :label="label"
    :list="entries"
    :state="isInEntries"
  />
</template>
<script>
import FilterBox from '../FilterBox';
import { Kategorie } from '../../../services/translation';

export default {
  name: 'Kategorie',
  components: { FilterBox },
  computed: {
    meta() {
      return this.$store.state.activeContentBlock.meta;
    },
    isValid() {
      return this.$store.state.activeContentBlock.type === 'article';
    },
    entries() {
      return Kategorie;
    },
    label() {
      if (this.$store.state.activeLng === 'de') {
        return 'Kategorie';
      }
      return 'Structure';
    },
    isInEntries() {
      let Dummy = [];
      for (let item of Kategorie) {
        Dummy.push(this.meta?.kategorie.includes(item.id));
      }
      return Dummy;
    }
  }
};
</script>

<style scoped></style>
